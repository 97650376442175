import { getDateDifference } from 'src/helper/utilityFunctions';

export const PlanTypeEnums = { MONTHLY: '0', BI_WEEKLY: '1', WEEKLY: '2', EVENT: '3', FLAT: '4' };

export const PlanColumns = ['0', '1', '2', '4'];

export const getPlanTypeTitles = (t) => {
  const titles = {
    [PlanTypeEnums.MONTHLY]: t('sales.contract.monthly'),
    [PlanTypeEnums.BI_WEEKLY]: t('sales.contract.biWeekly'),
    [PlanTypeEnums.WEEKLY]: t('sales.contract.weekly'),
    [PlanTypeEnums.EVENT]: t('sales.contract.event'),
    [PlanTypeEnums.FLAT]: t('sales.contract.flat'),
  };

  return titles;
};

export const FormKeys = {
  PLAN: 'plan',
  TAX_RATE: 'taxRate',
  FLAT_RATE: 'flatRate',
  FUEL_SURCHARGE: 'fuelSurcharge',
  CYCLE_REF_DATE: 'cycleRefDate',
  PAYMENT_METHOD: 'paymentMethod',
  BILLABLE: 'billable',
  PAYABLE: 'payable',
  PAYMENT_DATE: 'paymentDate',
  ANNUAL_RATE_INCREASE: 'annualRateIncrease',
  HOLIDAY_MULTIPLIER: 'holidayMultiplier',
  HOLIDAY_GROUP: 'holidayGroup',
  BILLING_TYPE: 'billingType',
  BILLING_FREQUENCY: 'billingFrequency',
  CONTRACT_TYPE: 'contractType',
  BILLING_OWNER_FIRST_NAME: 'billingOwnerFirstName',
  BILLING_OWNER_LAST_NAME: 'billingOwnerLastName',
  BILLING_OWNER_EMAIL: 'billingOwnerEmail',
  BILLING_OWNER_PHONE: 'billingOwnerPhone',
  HAS_DIFFERENT_BILLING_ADDRESS: 'hasDifferentBillingAddress',
  ADDRESS: 'address',
  STREET_ADDRESS: 'streetAddress',
  COUNTRY: 'country',
  STATE: 'state',
  CITY: 'city',
  COUNTRY_CODE: 'countryCode',
  POSTAL_CODE: 'postalCode',
  LEAD_ADDRESS: 'leadAddress',
  BILLING_ADDRESS: 'billingAddress',
};

export const paymentTermsEmptyState = {
  [FormKeys.PLAN]: PlanTypeEnums.WEEKLY.toString(),
  [FormKeys.TAX_RATE]: null,
  [FormKeys.FLAT_RATE]: null,
  [FormKeys.PAYMENT_METHOD]: null,
  [FormKeys.CYCLE_REF_DATE]: null,
  [FormKeys.PAYMENT_DATE]: null,
  [FormKeys.ANNUAL_RATE_INCREASE]: null,
  [FormKeys.HOLIDAY_MULTIPLIER]: null,
  [FormKeys.HOLIDAY_GROUP]: null,
  [FormKeys.BILLING_TYPE]: null,
  [FormKeys.CONTRACT_TYPE]: null,
  [FormKeys.BILLING_OWNER_FIRST_NAME]: null,
  [FormKeys.BILLING_OWNER_LAST_NAME]: null,
  [FormKeys.BILLING_OWNER_EMAIL]: null,
  [FormKeys.BILLING_OWNER_PHONE]: null,
  [FormKeys.HAS_DIFFERENT_BILLING_ADDRESS]: false,
  [FormKeys.ADDRESS]: null,
  [FormKeys.COUNTRY]: null,
  [FormKeys.STATE]: null,
  [FormKeys.CITY]: null,
  [FormKeys.COUNTRY_CODE]: null,
  [FormKeys.POSTAL_CODE]: null,
};

export const getPaymentTermsData = (data, isFuelSurchargeApplicable) => {
  const paymentDate = { value: data?.paymentDate?.id, label: data?.paymentDate?.name };
  const paymentMethod = { value: data?.paymentMethod?.id, label: data?.paymentMethod?.name };
  const holidayGroup = {
    value: data?.holidayGroup?.id,
    label: data?.holidayGroup?.name,
    holidays: data?.holidayGroup?.holidays,
    holidaysCount: data?.holidayGroup?.holidays?.length,
  };
  const billingType = { value: data?.billingType?.id, label: data?.billingType?.name };
  const contractType = { value: data?.contractType?.id, label: data?.contractType?.name };
  const billingFrequency = {
    value: data?.billingFrequency?.id,
    label: data?.billingFrequency?.name,
  };
  const plan = data?.plan?.id?.toString()
    ? data?.plan?.id?.toString()
    : data?.startDate
      ? getDateDifference(data?.startDate, data?.endDate) < 7
        ? PlanTypeEnums.EVENT
        : PlanTypeEnums.WEEKLY
      : PlanTypeEnums.WEEKLY;
  const billingAddress = {
    streetAddress: data.address.streetAddress,
    country: data.address.country,
    state: data.address.state,
    city: data.address.city,
    countryCode: data.address.country,
    postalCode: data.address.postalCode,
  };

  const leadAddress = {
    streetAddress: data?.leadAddress.streetAddress || '',
    country: data?.leadAddress.country || '',
    state: data?.leadAddress?.state || '',
    city: data?.leadAddress?.city || '',
    countryCode: data?.leadAddress?.country?.id || '',
    postalCode: data?.leadAddress?.postalCode || '',
  };

  let payload = {};

  if (isFuelSurchargeApplicable && plan !== PlanTypeEnums.FLAT)
    payload[FormKeys.FUEL_SURCHARGE] = data[FormKeys.FUEL_SURCHARGE];

  payload = {
    ...payload,
    [FormKeys.PLAN]: plan,
    [FormKeys.TAX_RATE]: data[FormKeys.TAX_RATE],
    [FormKeys.FLAT_RATE]: data[FormKeys.FLAT_RATE],
    [FormKeys.PAYMENT_METHOD]: paymentMethod,
    [FormKeys.HOLIDAY_GROUP]: holidayGroup,
    [FormKeys.CYCLE_REF_DATE]: data[FormKeys.CYCLE_REF_DATE],
    [FormKeys.PAYMENT_DATE]: paymentDate,
    [FormKeys.BILLABLE]: data[FormKeys.BILLABLE],
    [FormKeys.PAYABLE]: data[FormKeys.PAYABLE],
    [FormKeys.ANNUAL_RATE_INCREASE]: data[FormKeys.ANNUAL_RATE_INCREASE],
    [FormKeys.HOLIDAY_MULTIPLIER]: data[FormKeys.HOLIDAY_MULTIPLIER],
    [FormKeys.BILLING_TYPE]: billingType,
    [FormKeys.CONTRACT_TYPE]: contractType,
    [FormKeys.BILLING_OWNER_FIRST_NAME]: data[FormKeys.BILLING_OWNER_FIRST_NAME],
    [FormKeys.BILLING_OWNER_LAST_NAME]: data[FormKeys.BILLING_OWNER_LAST_NAME],
    [FormKeys.BILLING_OWNER_EMAIL]: data[FormKeys.BILLING_OWNER_EMAIL],
    [FormKeys.BILLING_OWNER_PHONE]: data[FormKeys.BILLING_OWNER_PHONE],
    [FormKeys.HAS_DIFFERENT_BILLING_ADDRESS]: data[FormKeys.HAS_DIFFERENT_BILLING_ADDRESS],
    [FormKeys.ADDRESS]: data.address.streetAddress,
    [FormKeys.COUNTRY]: data.address.country.id,
    [FormKeys.STATE]: data.address.state.id,
    [FormKeys.CITY]: data.address.city.id,
    [FormKeys.COUNTRY_CODE]: data.address.country.id,
    [FormKeys.POSTAL_CODE]: data.address.postalCode,
    [FormKeys.LEAD_ADDRESS]: leadAddress,
    [FormKeys.BILLING_ADDRESS]: billingAddress,
    [FormKeys.BILLING_FREQUENCY]: billingFrequency,
  };

  return payload;
};
